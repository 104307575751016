import axios from '@axios'

export const fetchGroupListTemplatesList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/group-list-templates', { params })
  return res
}

export const fetchGroupListTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/group-list-templates/${id}`)
  return res
}

export const createGroupListTemplate = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/group-list-templates', params)
  return res
}

export const updateGroupListTemplate = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/group-list-templates/${params.id}`, params)
  return res
}

export const deleteGroupListTemplate = async (id: string): Promise<any> => {
  const res = await axios.delete(`/auth/group-list-templates/${id}`)
  return res
}

export const cloneGroupListTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/group-list-templates/${id}/clone`)
  return res
}
export const fetchGroupTypePlaceholders = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/group-list-templates-placeholders', { params })
  return res
}
